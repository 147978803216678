<template>
  <div
    v-if="nft"
    class="my-page fit row wrap justify-center items-start content-start"
  >
    <div class="col-xs-12 col-md-6 q-pa-md">
      <div class="frame" @click="show_image(nft)">
        <div class="mat">
          <div class="art">
            <q-img :ratio="1" :src="nft.data.image">
              <a v-if="nft.data.link" @click.stop="goto(nft.data.link)">
                <q-icon
                  class="articon absolute all-pointer-events"
                  name="link"
                  size="48px"
                  color="white"
                  style="right: 16px"
                >
                </q-icon>
              </a>
              <a v-if="nft.data.video" @click.stop="goto(nft.data.video)">
                <q-icon
                  class="articon absolute all-pointer-events"
                  name="videocam"
                  size="48px"
                  color="white"
                  style="right: 68px"
                >
                </q-icon>
              </a>
              <a v-if="nft.data.audio" @click.stop="goto(nft.data.audio)">
                <q-icon
                  class="articon absolute all-pointer-events"
                  style="right: 120px"
                  size="48px"
                  color="white"
                  name="audiotrack"
                >
                </q-icon>
              </a>
            </q-img>
          </div>
        </div>
      </div>
    </div>

    <q-card flat class="col-xs-12 col-md-6">
      <q-card-section class="q-ml-md">
        <p class="text-h5 text-bold">
          {{ nft.data.name }} 
          <q-icon class="q-ml-lg q-ma-xs" color="red" size="24px" name="favorite" />
          <span class="text-grey">{{ nft.likes }}</span>
        </p>

        <p
          v-if="Number(nft.data.price) != 0"
          class="text-h5 text-blue text-bold"
        >
          ${{ nft.data.price }}
        </p>
        <p v-else class="text-accent">{{ $t("free") }}</p>

        <p class="text-body2">
          {{ $t("create_date") }}: {{ pdate(nft.created_at) }}
        </p>
        <p class="text-body2">
          {{ $t("total") }}: {{ nft.total }} | {{ $t("remains") }}:
          {{ nft.total - nft.token_sum > 0 ? nft.total - nft.token_sum : 0 }}
        </p>
        <p class="text-body2">
          {{ $t("contract") }}:
          <a :href="chainlnk">{{ nft.id.substring(0, 18) + "..." }}</a>
        </p>

        <p class="text-subtitle2">{{ $t("author") }}: {{ nft.data.author }}</p>
        <p class="text-subtitle2">{{ $t("about") }}</p>
        <p class="text-body">
          {{ nft.data.description }}
        </p>

        <div v-if="nft.total - nft.token_sum <= 0">
          <q-btn
            class="full-width q-ma-xs"
            color="grey"
            :label="$t('sold_out')"
            size="20px"
            disable
          />
        </div>

        <div v-else>
          <div v-if="!user">
            <q-btn
              v-if="Number(nft.data.price) == 0"
              class="full-width q-ma-xs"
              color="blue"
              :label="$t('free_claim')"
              size="20px"
              @click="login"
            />
            <q-btn
              v-else
              class="full-width q-ma-xs"
              color="blue"
              :label="$t('buy_now')"
              size="20px"
              @click="login"
            />
          </div>

          <div v-else>
            <div>
              <q-btn
                v-if="nft.data.is_credit_card"
                :loading="claiming"
                class="full-width q-ma-xs"
                color="orange"
                :label="$t('buy_now')"
                size="20px"
                @click="order_confirm = true"
              >
                <template v-slot:loading>
                  <q-spinner-hourglass class="on-left" />
                  Loading
                </template>
              </q-btn>
            </div>
          </div>
        </div>
        <input hidden type="text" :value="share_url" id="my_share_url" />
      </q-card-section>
    </q-card>
  </div>
 
  <q-dialog seamless full-width position="bottom" v-model="book_confirm">
    <q-card>
      <q-card-section class="q-mt-lg q-pt-none text-blue text-center">
        <p class="text-h6 text-orange">
          You Booked 1 NFT, will mint to your wallet soon!
        </p>
        <p class="text-body">{{ nft.id }}</p>
        <p class="text-body2"># {{ tokenid }}</p>
      </q-card-section>

      <q-card-actions align="center">
        <q-btn color="primary" v-close-popup>OK</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog seamless full-width position="bottom" v-model="confirm">
    <q-card>
      <q-card-section class="q-mt-lg q-pt-none text-blue text-center">
        <p class="text-h6 text-orange">{{$t("claimed_nft")}}</p>
        <p class="text-body">{{ nft.id }}</p>
        <p class="text-body2"># {{ tokenid }}</p>
        <p class="text-h6">
          <a
            class="text-body2"
            :href="chainscan_url + 'tx/' + receipt.transactionHash"
            >View Transaction</a
          >
        </p>
      </q-card-section>

      <q-card-actions align="center">
        <q-btn color="primary" v-close-popup>OK</q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
  <q-dialog seamless full-width position="bottom" v-model="alert">
    <q-card>
      <q-card-section>
        <div class="text-h6">Alert</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        {{ $t(alert_msg) }}
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat size="20px" label="OK" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="order_confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="shopping_cart" color="primary" text-color="white" />
        <span class="q-ml-sm"
          >{{ $t("you_are_buying") }} 1 NFT {{ nft.data.name }}.</span
        >
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" color="grey" v-close-popup />

        <q-btn
          flat
          :label="$t('make_payment')"
          color="blue"
          v-close-popup
          @click="buynow(nft)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="claim_confirm" persistent>
    <q-card>
      <q-card-section class="row items-center">
        <q-avatar icon="shopping_cart" color="primary" text-color="white" />
        <span class="q-ml-sm"
          >  {{ $t('free_claim') + " " + nft.data.name }} </span
        >
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat :label="$t('cancel')" color="grey" v-close-popup />
        <q-btn flat :label="$t('free_claim')" color="orange" v-close-popup @click="claim" />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog v-model="dialog" persistent class="mydialog">
    <q-card class="mycard">
      <div class="q-mp-lg absolute-top-right">
        <q-icon
          class="myclose"
          size="48px"
          name="close"
          color="white"
          @click="dialog = false"
        />
      </div>
      <q-card-section class="q-ma-lg">
        <a :href="cur_token_image" download="token-image">
          <div class="frame">
            <div class="mat">
              <div class="art">
                <q-img :ratio="1" fit="contain" :src="cur_token_image" />
              </div>
            </div>
          </div>
        </a>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<style lang="sass" scoped>
.my-page
  padding-left: 1rem
  padding-right: 1rem

.frame
  position: relative
  width: 100%
  padding-bottom: 100%
  background: black
  box-shadow: 0 10px 7px -5px rgba(0, 0, 0, 0.3)
  cursor: pointer

.mat
  position: absolute
  background: white
  top: 2.5%
  bottom: 2.5%
  left: 2.5%
  right: 2.5%
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.5) inset

.art
  position: absolute
  top: 5%
  // bottom: 5%
  left: 5%
  right: 5%

.art::after
  content: ''
  display: block
  position: absolute
  top: 0
  width: 100%
  height: 100%
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5) inset
.articon
  bottom: 8px
  box-shadow: 0px 0px 0px 0px rgba(0,0,0,1)
  text-shadow: 0 0 10px #000000
  z-index: 100


.my-list
  padding-left: 1rem
  padding-right: 1rem
  .my-card
    cursor: pointer
    .my-card-btn
      width: 100%

.my-more
  margin-top: 2rem
  margin-left: 2rem
  margin-bottom: 0
  padding-bottom: 0
  padding-left: 3rem
  padding-right: 3rem

.myclose
  cursor: pointer

.mycard
  width: 1024px
  max-width: 90vw
  background-color: rgba(255,255,255,0)
  box-shadow: 0 0 0 0 rgba(255,255,255,0)
</style>

<script>
import { useRoute } from "vue-router";
import { ref, onBeforeMount } from "vue";
import axios from "axios";
import { supabase as s} from "../supabase";
import router from "@/router";
import { useQuasar } from "quasar";
import { chainscan, APIURL, next_tid, next_token_id,likeit, share_to } from "../web3.js";
    import { date } from 'quasar'

export default {
  name: "NFT",
  setup() {
    console.log("NFT setup");
    const id = useRoute().params.id;
    // alert(id);
    const nft = ref();
    const morenfts = ref();
    const recent_txns = ref();
    const chainscan_url = ref(chainscan);
    const user = ref(s.auth.user());
    const share_url = ref("");
    onBeforeMount(async () => {
      // set refer to local storage, send to server when user signin
      {
        // set refer to local storage, send to server when user signin
        // if local has already has a referer or currently exist login user don't need set referer
        // only set referer for the clean new user first visit
        if (!localStorage.getItem("referer") && !user.value) {
          // query refer user info
          if (useRoute().query.rid == undefined) {
            console.log("No referer id, not a referer page");
          } else {
            const rid = useRoute().query.rid;
            console.log("referer uid", rid);
            let { data: u, error } = await s
              .from("users")
              .select("*")
              .eq("id", rid);
            if (error) console.log(error);
            else {
              console.log("Query refer user:", u[0]);
              localStorage.setItem("referer", u[0].id);
              console.log("set local referer id:", u[0].id);
            }
          }
        }
      }

      let { data: nfts, error } = await s
        .from("v_nfts")
        .select("*,uowner:uowner(*)")
        .eq("id", id);
      if (error) console.log(error);
      nft.value = nfts[0];
      console.log(nft.value);


      likeit(nft.value.id);
      console.log("userid:",user.value.id)
    });

    const chainlnk = chainscan_url.value + "address/" + id;
    const q = useQuasar();
    return {
      
      dialog: ref(false),
      chainscan_url,
      tokenid: ref(0),
      q,
      confirm: ref(false),
      alert: ref(false),
      receipt: ref({}),
      alert_msg: ref(""),
      claiming: ref(false),
      nft,
      morenfts,
      chainlnk,
      user,
      order_confirm: ref(false),
      claim_confirm: ref(false),
      user_buy_method: ref(""),
      cur_token_image: ref(""),
      share_url,
      share_to,
      book_confirm:ref(false),
      recent_txns,
      columns: [
        {
          name:'id',
          required:true,
          label:'ID',
          field:'id'
        },
        {
          name:'type',
          label:'TYPE',
          field:'type'
        },          
        {
          name:'tokenid',
          label:'TOKEN ID',
          field:'tid'
        },        
        {
          name:'to',
          required:true,
          label:'TO',
          field:'to'
        },
        {
          name:'status',
          label:'STATUS',
          field:'status'
        },
        {
          name:'created_at',
          label:'DATE',
          field:'created_at',
          format: val => date.formatDate(val, 'YYYY-MM-DD HH:mm:ss')

        }
      ]
    };
  },

  methods: {
    // opensea(nft) {
    //   window.open("https://opensea.io/assets/matic/" + nft.contract);
    // },
    goto(u) {
      window.open(u);
    },
    show_image(k) {
      console.log("Show image");
      this.dialog = true;
      this.cur_token_image = k.data.image;
    },
    login() {
      console.log("call login");
      console.log("src:",window.location.pathname)
      this.$router.push({
          path:"/login",
          query:{
            src:window.location.pathname
          }
        });
    },
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("en-US").format(
        date
      );
    },

    async buynow(nft) {
      const to_addr = this.user.user_metadata.wallet;
      const tid = next_tid();
      // Insert TXN record
      const record = {
        ufrom: this.nft.uowner.id,
        uto: this.user.id,
        contract: this.nft.id,
        tid: tid,
        from: "NULL",
        to: to_addr,
        price: this.nft.data.price,
        type: "CREDIT_CARD",
        status: "PENDING",
      };
      const { data, error } = await s.from("txns").insert([record]);

      if (error) {
        console.log(error);
        return;
      } else {
        console.log(data[0]);
        this.$router.push("/checkout/" + data[0].id);
      }
    },
  },
};
</script>
